let accordionBtn = document.querySelectorAll("[data-accordian-toggle]");

function toggleAccordian(bool, id) {
  let header = document.querySelector(`[data-accordian-id="${id}"]`);
  let body = document.querySelector(`${id}`);

  let bodyHeight = 0;
  if (bool) {
    header.setAttribute("aria-expanded", "true");
    header.setAttribute("data-accordian-toggle", "expand");
    body.classList.add("collapsing");

    setTimeout(() => {
      body.classList.add("show");
      bodyHeight = document.querySelector(`${id} .card-body`).offsetHeight;
      body.style.height = `${bodyHeight}px`;
    }, 1);
    setTimeout(() => {
      body.classList.remove("collapsing");
      body.setAttribute("style", "");
    }, 500);
  } else {
    header.setAttribute("aria-expanded", "false");
    header.setAttribute("data-accordian-toggle", "collapse");
    bodyHeight = document.querySelector(`${id}`).offsetHeight;
    body.style.height = `${bodyHeight}px`;
    body.classList.add("collapsing");

    setTimeout(() => {
      body.setAttribute("style", "");
    }, 1);
    setTimeout(() => {
      body.classList.remove("show");
      body.classList.remove("collapsing");
    }, 500);
  }
}

if (accordionBtn.length > 0) {
  accordionBtn.forEach((el) => {
    el.addEventListener("click", (e) => {
      let isCollapsed = e.target.getAttribute("data-accordian-toggle") === "collapse";
      let thisId = e.target.getAttribute("data-accordian-id");

      if (isCollapsed) {
        toggleAccordian(true, thisId);
      } else {
        toggleAccordian(false, thisId);
      }
    });
  });
}
